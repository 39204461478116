import { Routes } from '@angular/router';
import { MesaRegalosLandingComponent } from './pages/mesa-regalos-landing/mesa-regalos-landing.component';

export const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'silhouette-cameo-5/6700-paquete-hot-stamping-con-silhouette-cameo-5.html', redirectTo: 'paquetes/6700-SILH-0047.html', pathMatch: 'full' },
    { path: 'silhouette-plotter-de-corte/3900-4410-1900.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5491-paquete-silhouette-cameo-4-designer-edition.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5021-SILH-0004.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6670-combo-plotter-de-corte-silhouette-cameo-4-negro-rotulacion.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6853-duo-silhouette-cameo-4-rojo-laminadora-foil.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6852-SILH-0001-BLK.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6851-SILH-0001-RED.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette0', pathMatch: 'full' },
    { path: 'paquetes/6290-paquete-cameo-4-empire-red-laminadora-luminos-y-viniles.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6853-SILH-0003-RED.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6854-SILH-0003-BLK.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6527-SILH-0028.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6851-13393-paquete-silhouette-cameo-4-rojo-laminadora.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5026-SILH-0015.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/4999-SILH-0003.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6528-SILH-0029.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/6352-SILH-0038.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5022-5443-SILH-0008.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6527-duo-silhouette-cameo-4-color-blanco-laminadora-pink-9.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5027-SILH-0016.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5490-paquete-silhouette-cameo-4-stickers.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'inicio/5000-SILH-0005.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'inicio/5020-5441-SILH-0002.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/4998-SILH-0001.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'silhouette-cubiertas-totes/4450-4420-1995.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5024-SILH-0012.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'silhouette-plotter-de-corte/3901-4410-1902.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5839-paquete-silhouette-cameo-4-bw.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6352-duo-blanco-minc-silhouette-cameo-4-laminadora-12.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5022-SILH-0008.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5000-SILH-0005.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5000-SILH-0005.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'silhouette-plotter-de-corte/6285-plotter-de-corte-silhouette-cameo-4-color-rojo.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6668-combo-plotter-de-corte-silhouette-cameo-4-rojo-rotulacion.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6352-duo-blanco-minc-silhouette-cameo-4-laminadora-12.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/5022-SILH-0008.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes/5000-SILH-0005.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6668-combo-plotter-de-corte-silhouette-cameo-4-rojo-rotulacion.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6292-SILH-0033.html', redirectTo: 'catalogo-paquetes/493/paquetes-silhouette', pathMatch: 'full' },
    { path: 'silhouette-plotter-de-corte/6285-plotter-de-corte-silhouette-cameo-4-color-rojo.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes-silhouette-cameo/6528-duo-silhouette-cameo-4-color-blanco-laminadora-pink-13.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6855-paquete-silhouette-lovers-rojo.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/6856-paquete-silhouette-lovers-negro.html', redirectTo: 'catalogo/1960/plotters-de-corte', pathMatch: 'full' },
    { path: 'paquetes/7083-PREV-4425-0007.html', redirectTo: 'cricut/7083-plotter-de-corte-cricut-explore-air-2-azul-2005420.html', pathMatch: 'full' },



    {
        path: 'home',
        loadComponent: () => import('./pages/home/home.component')
            .then((a) => a.HomeComponent)
    },
    {
        path: 'login',
        loadComponent: () => import('./pages/login/login.component')
            .then((b) => b.LoginComponent)
    },
    {
        path: 'registro',
        loadComponent: () => import('./pages/modal-registro/modal-registro.component')
            .then((b) => b.ModalRegistroComponent)
    },
    {
        path: 'recuperar-cuenta',
        loadComponent: () => import('./pages/modal-recuperar/modal-recuperar.component')
            .then((b) => b.ModalRecuperarComponent)
    },
    {
        path: 'cart',
        loadComponent: () => import('./pages/cart/cart.component')
            .then((c) => c.CartComponent)
    },
    {
        path: 'blog',
        loadComponent: () => import('./pages/blogsito/blogsito.component')
            .then((d) => d.BlogsitoComponent)
    },
    {
        path: 'articulo-blog/:id_blog',
        loadComponent: () => import('./pages/articulo-blog/articulo-blog.component')
            .then((e) => e.ArticuloBlogComponent)
    },
    {
        path: 'contacto',
        loadComponent: () => import('./pages/contacto/contacto.component')
            .then((f) => f.ContactoComponent)
    },
    {
        path: 'cursos-gratis',
        loadComponent: () => import('./pages/cursos-gratis/cursos-gratis.component')
            .then((g) => g.CursosGratisComponent)
    },
    {
        path: 'distribuidor',
        loadComponent: () => import('./pages/distribuidor/distribuidor.component')
            .then((h) => h.DistribuidorComponent)
    },
    {
        path: 'lidepuntos',
        loadComponent: () => import('./pages/lidepuntos/lidepuntos.component')
            .then((i) => i.LidepuntosComponent)
    },
    {
        path: 'compra-y-recoge',
        loadComponent: () => import('./pages/compra-y-recoge/compra-y-recoge.component')
            .then((i) => i.CompraYRecogeComponent)
    },
    {
        path: 'conoce-mas-de-varali',
        loadComponent: () => import('./pages/conoce-mas-de-varali/conoce-mas-de-varali.component')
            .then((i) => i.ConoceMasDeVaraliComponent)
    },
    {
        path: 'conoce-mas-de-luminos',
        loadComponent: () => import('./pages/conoce-mas-de-luminos/conoce-mas-de-luminos.component')
            .then((i) => i.ConoceMasDeLuminosComponent)
    },
    {
        path: 'rally',
        loadComponent: () => import('./pages/rally/rally.component')
            .then((i) => i.RallyComponent)
    },
    {
        path: 'clases-creativas',
        loadComponent: () => import('./pages/clases-creativas/clases-creativas.component')
            .then((i) => i.ClasesCreativasComponent)
    },
    {
        path: 'especificaciones-plotters-cricut',
        loadComponent: () => import('./pages/especificaciones-plotters-cricut/especificaciones-plotters-cricut.component')
            .then((i) => i.EspecificacionesPlottersCricutComponent)
    },
    {
        path: 'codigo-qr',
        loadComponent: () => import('./pages/codigo-qr/codigo-qr.component')
            .then((i) => i.CodigoQrComponent)
    },
    {
        path: 'faq',
        loadComponent: () => import('./pages/faq/faq.component')
            .then((j) => j.FaqComponent)
    },
    {
        path: 'nosotros',
        loadComponent: () => import('./pages/nosotros/nosotros.component')
            .then((k) => k.NosotrosComponent)
    },
    {
        path: 'resetPassword',
        loadComponent: () => import('./pages/reseteo-password/reseteo-password.component')
            .then((l) => l.ReseteoPasswordComponent)
    },
    {
        path: 'venta/:ov',
        loadComponent: () => import('./pages/resumen-venta/resumen-venta.component')
            .then((n) => n.ResumenVentaComponent)
    },
    {
        path: 'software-de-equipos',
        loadComponent: () => import('./pages/software-de-equipos/software-de-equipos.component')
            .then((o) => o.SoftwareDeEquiposComponent)
    },
    {
        path: 'paquetes/:id_product-:sku',
        loadComponent: () => import('./pages/descripcion-paquetes/descripcion-paquetes.component')
            .then((p) => p.DescripcionPaquetesComponent)
    },
    {
        path: 'catalogo/:id_categoria/:link_rewrite',
        loadComponent: () => import('./pages/catalogo/catalogo.component')
            .then((r) => r.CatalogoComponent)
    },
    {
        path: 'categoria/:id_parent/:link_rewrite',
        loadComponent: () => import('./pages/categorias/categorias.component')
            .then((s) => s.CategoriasComponent)
    },
    {
        path: 'paquetes',
        loadComponent: () => import('./pages/categoria-por-paquetes/categoria-por-paquetes.component')
            .then((t) => t.CategoriaPorPaquetesComponent)
    },
    {
        path: 'catalogo-paquetes/:id_categoria/:link_rewrite',
        loadComponent: () => import('./pages/catalogo-paquetes/catalogo-paquetes.component')
            .then((u) => u.CatalogoPaquetesComponent)
    },
    {
        path: 'categorias',
        loadComponent: () => import('./pages/tipos-categorias/tipos-categorias.component')
            .then((v) => v.TiposCategoriasComponent)
    },
    {
        path: 'checkout',
        loadComponent: () => import('./pages/checkout/checkout.component')
            .then((w) => w.CheckoutComponent)
    },
    {
        path: 'perfil/:rec',
        loadComponent: () => import('./pages/perfil/perfil.component')
            .then((ww) => ww.PerfilComponent)
    },
    {
        path: 'wishlist',
        loadComponent: () => import('./pages/wishlist/wishlist.component')
            .then((z) => z.WishlistComponent)
    },
    {
        path: 'promociones-cricut',
        loadComponent: () => import('./pages/categoria-promociones-cricut/categoria-promociones-cricut.component')
            .then((ht) => ht.CategoriaPromocionesCricutPage)
    },
    {
        path: 'formulario-expo',
        loadComponent: () => import('./pages/formulario-expo/formulario-expo.component')
            .then((re) => re.FormularioExpoComponent)
    },
    {
        path: 'google-search/:busqueda',
        loadComponent: () => import('./pages/google-search/google-search.component')
            .then((gs) => gs.GoogleSearchComponent)
    },
    {
        path: 'reviews',
        loadComponent: () => import('./pages/reviews/reviews.component')
            .then((zzss) => zzss.ReviewsComponent)
    },
    {
        path: 'direcciones',
        loadComponent: () => import('./pages/administra-direcciones/administra-direcciones.component')
            .then((fgju) => fgju.AdministraDireccionesComponent)
    },
    {
        path: 'resumen-compra',
        loadComponent: () => import('./pages/resumen-compra/resumen-compra.component')
            .then((iiookk) => iiookk.ResumenCompraComponent)
    },
    {
        path: '840-brother',
        loadComponent: () => import('./pages/brotha/brotha.component')
            .then((brotha) => brotha.BrothaComponent)
    },
    {
        path: 'mi-perfil',
        loadComponent: () => import('./pages/mi-perfil/mi-perfil.component')
            .then((perfilsito) => perfilsito.MiPerfilComponent)
    },
    {
        path: 'facturacion',
        loadComponent: () => import('./pages/facturacion/facturacion.component')
            .then((factur) => factur.FacturacionComponent)
    },
    {
        path: 'mesa-regalos/:id_customer',
        loadComponent: () => import('./pages/mesa-regalos/mesa-regalos.component')
            .then((mesa) => mesa.MesaRegalosComponent)
    },
    {
        path: 'mesa-regalos-caja/:id_customer',
        loadComponent: () => import('./pages/mesa-regalos-caja/mesa-regalos-caja.component')
            .then((mesacaja) => mesacaja.MesaRegalosCajaComponent)
    },
    {
        path: 'mesa-regalos-conf',
        loadComponent: () => import('./pages/mesa-regalos-conf/mesa-regalos-conf.component')
            .then((mesaconf) => mesaconf.MesaRegalosConfComponent)
    },
    {
        path: 'mesa-regalos-landing',
        loadComponent: () => import('./pages/mesa-regalos-landing/mesa-regalos-landing.component')
            .then((mesaLand) => mesaLand.MesaRegalosLandingComponent)
    },
    {
        path: 'mesa-regalos-principal',
        loadComponent: () => import('./pages/mesa-regalos-principal/mesa-regalos-principal.component')
            .then((mesaPrinc) => mesaPrinc.MesaRegalosPrincipalComponent)
    },
    {
        path: 'mesa-regalos-registro',
        loadComponent: () => import('./pages/mesa-regalos-registro/mesa-regalos-registro.component')
            .then((mesaRegis) => mesaRegis.MesaRegalosRegistroComponent)
    },
    {
        path: 'mesa-escoge-regalos',
        loadComponent: () => import('./pages/mesa-regalos-elegir/mesa-regalos-elegir.component')
            .then((mesaElige) => mesaElige.MesaRegalosElegirComponent)
    },
    {
        path: 'mesa-regalos-tipos',
        loadComponent: () => import('./pages/mesa-regalos-tipos/mesa-regalos-tipos.component')
            .then((mesaTipos) => mesaTipos.MesaRegalosTiposComponent)
    },
    {
        path: 'herramienta-productos-relacionados',
        loadComponent: () => import('./pages/productos-relacionados-tool/productos-relacionados-tool.component')
            .then((prorelca) => prorelca.ProductosRelacionadosToolComponent)
    },
    {
        path: 'mesa-regalos-tipo/:id',
        loadComponent: () => import('./pages/mesa-regalos-tipo/mesa-regalos-tipo.component')
            .then((mesaTipo) => mesaTipo.MesaRegalosTipoComponent)
    },
    {
        path: 'mesa-regalos-construct',
        loadComponent: () => import('./pages/mesa-regalos-construct/mesa-regalos-construct.component')
            .then((mesaConstruct) => mesaConstruct.MesaRegalosConstructComponent)
    },
    {
        path: 'creative-gift',
        loadComponent: () => import('./pages/creative-gift/creative-gift.component')
            .then((creativooooo) => creativooooo.CreativeGiftComponent)
    },
    // {
    //     path: 'clases-creativas',
    //     loadComponent: () => import('./pages/clases-creativas/clases-creativas.component')
    //     .then((clasesssss) => clasesssss.ClasesCreativasComponent )
    // },

    {
        path: 'mesa-regalos-ver',
        loadComponent: () => import('./pages/mesa-regalos-ver/mesa-regalos-ver.component')
            .then((mesaVer) => mesaVer.MesaRegalosVerComponent)
    },
    {
        path: 'ejecutivoskam',
        loadComponent: () => import('./pages/experiencia-compra/experiencia-compra.component')
            .then((expcompra) => expcompra.ExperienciaCompraComponent)
    },

    {
        path: 'terminos-politicas/:id',
        loadComponent: () => import('./pages/terminos-politicas/terminos-politicas.component')
            .then((tp) => tp.TerminosPoliticasComponent)
    },
    //#########################esta ruta debe estar siempre semifinal###################################################################
    {
        path: ':category/:id_product-:link_rewrite',
        loadComponent: () => import('./pages/descripcion-producto/descripcion-producto.component')
            .then((q) => q.DescripcionProductoComponent)
    },
    //#########################esta ruta debe estar siempre al final si o si###################################################################
    /*{
        path: '**',
        redirectTo: 'google-search'
    }*/
];
